@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.App {
	text-align: center;
	overflow-x: hidden;
}

html,
body {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}

	.navbarLink {
		display: none;
	}
}

/*media queries*/
/*
@media (min-width:100px) and (max-width: 590px){
	.wavesPeopleAchiraGroup {
		
		background-position-y: 55vw;
		
	}
	.navbarLink {
		display: none;
	}
}
*/

/* min-width:591px*/
@media (max-width: 1060px) {
	.chipsCardContainerDiv {
		flex-direction: column;
	}

	.chipsCardSection {
		height: 85vh;
		min-height: 230vh;
	}

	.chipsCardOuterDiv {
		width: 100%;
		margin-bottom: 3vh;
	}

	.colombianProductDiv {
		padding-right: 2vw;
		padding-left: 2vw;
	}

	.contactInfoBox {
		width: 90%;
	}

	.contactThreeSectionDiv {
		flex-direction: column;
		align-items: center;
	}

	.faqDiv {
		flex-direction: column;
		align-items: center;
	}

	.faqDiv > div,
	.faqDiv > div > div {
		width: 80vw;
	}

	.footerThreeColumns {
		flex-direction: column;
		justify-content: center;
		margin-top: 13.5vh;
	}

	.footerThreeColumns div {
		text-align: center;
		margin-top: 1vh;
		margin-bottom: 1.5vh;
		justify-content: center;
		margin-right: 2vw;
		margin-left: 2vw;
		margin-top: 3.5vh;
	}

	.footerCenterText {
		text-align: center;
		padding-left: 0;
	}

	.footerCenterTextOuterDiv {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.footerLinkText {
		align-items: center;
		justify-content: center;
	}

	.footerLogo {
		height: 35vw;
		margin-bottom: -100vh;
		z-index: 5;
		position: relative;
	}

	.healthRegisterFirstLeftSection {
		width: 70vw;
	}

	.healthRegisterSection div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.healthRegisterSection div div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 2.5vh;
	}

	.ingredientsCheckList {
		font-size: 1.2rem;
	}

	.navbarFlag {
		width: 25.5vw;
	}

	.navbarHamburgerMenu {
		display: inline;
	}

	.navbarLink {
		display: none;
	}

	.productBannerRowDiv {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.productBannerRowDiv {
		margin-top: 2vh;
	}

	.productBannerRowDiv h2 {
		font-size: 2.5em;
	}

	.productBannerRowDiv h2,
	.productBannerRowDiv h4,
	.productBannerRowDiv p {
		text-align: center;
	}

	.productHeaderImage {
		width: 45vw;
	}

	.productHeaderText {
		font-size: 1.1rem;
	}

	.productHeaderTextDiv {
		align-items: center;
	}

	.snackPresentationDiv {
		width: 75vw;
	}

	.snackPresentationHeader {
		font-size: 1.6rem;
	}

	.snackPresentationSection {
		flex-direction: column;
	}

	.startPageColombianFlagDiv {
		display: none;
	}

	.startChipsThreeSectionDiv {
		flex-direction: column;
	}

	.startPageHeaderOne {
		font-size: 1.2rem;
	}

	.startPageMiniBanner {
		flex-direction: column;
	}

	.startPageSecondBannerText {
		color: black;
		font-size: 1rem;
		width: 45vw;
	}

	#startPageSlides {
		width: 50vw;
	}

	.startPageSlideShowAndText {
		flex-direction: column;
		justify-content: space-around;
	}

	.startPageSlideShowAndTextParagraph {
		font-size: 1rem;
	}

	.startPageSlideShowAndTextSecondDiv {
		width: 60vw;
	}

	.usFirstSectionDiv {
		flex-direction: column;
	}
	.usFirstSectionDiv div {
		width: 100%;
	}

	.usFirstSectionDiv div img {
		width: 100%;
	}

	.usOrangeCompanySection div {
		display: flex;
		flex-direction: column;
	}

	.usOrangeCompanySection div div p {
		text-align: center;
	}

	.usSlideShow {
		padding-left: 3vw;
		padding-right: 3vw;
		margin-bottom: 5vh;
	}

	.usSlideShow div {
		padding-right: 0;
	}

	.usSlideShowSection {
		flex-direction: column;
	}

	.usSlideShowSection div {
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.extraTopPaddingOnTopTwoPointFive {
		padding-top: 2.5vh;
	}

	.wavesPeopleAchiraGroup {
		display: flex;
		flex-direction: row;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: 220vw 160vw;
		background-position-x: center;
		background-position-y: 10vw;
		margin-top: -20vh;
		height: 60vh;

		margin-bottom: -1vh;
	}

	.wavesPeopleAchiraGroupPageTitle {
		display: none;
	}

	
}

@media (max-width: 1060px) and (orientation: landscape) {
	.footerLogo {
		height: 15vw;
		margin-bottom: -100vh;
		z-index: 5;
		position: relative;
	}

	.wavesPeopleAchiraGroup {
		display: flex;
		flex-direction: row;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: 80vw 80vw;
		background-position-x: center;
		background-position-y: 0vh;
		margin-top: -20vh;
		height: 60vh;

		margin-bottom: -1vh;
	}

	.chipsCardSection {
		height: auto;
		min-height: 230vh;
	}

	.chipsCardContainerDiv {
		margin-top: 7vh;
		margin-bottom: 7vh;
	}

	.chipsCardOuterDiv {
		margin-top: 2vh;
	}

	.chipsCardInnerDiv {
		margin-top: 2.5vh;
	}

	.ingredientsCheckList {
		font-size: 1.2rem;
	}

	.healthRegisterFirstLeftSection {
		width: 70%;
	}
}

@media (min-width: 1061px) {
	.navbarFlag {
		width: 15.5vw;
	}

	.chipsCardContainerDiv {
		flex-direction: row;
	}

	.chipsCardSection {
		height: 85vh;
	}

	.chipsCardOuterDiv {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 30%;
	}

	.colombianProductDiv {
		padding-right: 10vw;
		padding-left: 6vw;
	}

	.contactInfoBox {
		width: 30%;
	}

	.contactThreeSectionDiv {
		flex-direction: row;
	}

	.faqDiv {
		flex-direction: row;
		justify-content: space-around;
	}

	.faqDiv > div,
	.faqDiv > div > div {
		width: 40vw;
	}

	.footerLogo {
		height: 60%;
		margin-bottom: -100vh;
		z-index: 5;
		position: relative;
	}

	.footerThreeColumns {
		flex-direction: row;
		justify-content: center;
	}

	.footerThreeColumns div {
		max-width: 40vw;
	}

	.healthRegisterSection > div {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.healthRegisterFirstLeftSection {
		width: 45%;
	}

	.ingredientsCheckList {
		font-size: 1.45rem;
	}

	.productBannerRowDiv {
		flex-direction: row;
		justify-content: center;
	}

	.productBannerRowDiv h2 {
		font-size: 5em;
	}

	.productHeaderImage {
		width: 25vw;
	}

	.productHeaderText {
		font-size: 1.2rem;
	}

	.productHeaderTextDiv {
		text-align: left;
	}

	.navbarHamburgerMenu {
		display: none !important;
	}

	.navbarLink {
		display: flex;
		flex-direction: row;
	}

	.snackPresentationDiv {
		width: 30vw;
	}

	.snackPresentationHeader {
		font-size: 1.5rem;
	}

	.snackPresentationImg {
		height: 80%;
	}

	.snackPresentationSection {
		flex-direction: row;
	}

	.startPageColombianFlagDiv {
		display: flex;
	}
	.startChipsThreeSectionDiv {
		flex-direction: row;
	}

	.startPageHeaderOne {
		font-size: 4rem;
	}

	.startPageMiniBanner {
		flex-direction: row;
	}

	.startPageSecondBannerText {
		color: black;
		font-size: 3rem;
		width: 45vw;
	}

	#startPageSlides {
		width: 30vw;
	}

	.startPageSlideShowAndText {
		flex-direction: row;
	}

	.startPageSlideShowAndTextParagraph {
		font-size: 1.2rem;
	}

	.startPageSlideShowAndTextSecondDiv {
		width: 40%;
	}

	.usFirstSectionDiv {
		flex-direction: row;
	}

	.usFirstSectionDiv div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 6%;
		padding-left: 2%;
	}

	.usFirstSectionDiv div img {
		width: 100%;
	}

	.usOrangeCompanySection div {
		display: flex;
		flex-direction: row;
	}

	.usSlideShow {
		padding-left: 7vw;
		padding-right: 0;
	}

	.usSlideShowSection {
		flex-direction: row;
	}

	.wavesPeopleAchiraGroup {
		display: flex;
		flex-direction: row;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: 100vw 60vw;
		background-position-x: center;
		background-position-y: 5vw;
		margin-top: -20vh;
		height: 60vh;

		margin-bottom: -1vh;
	}
}

/*END OF MEDIA QUERIES*/

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.carouselImage {
	border-radius: 10px;
}

.carouselSlideShow {
	width: 85%;
	border-radius: 10px;
	margin-bottom: 4vh;
	margin-top: -5vh;
}

.checked {
	color: orange;
	margin-right: 0.1vw;
	margin-left: 0.1vw;
	font-size: 1.2em;
}

.chipsCardContainerDiv {
	width: 80%;
	height: 90%;
	min-height: 90%;
	display: flex;

	justify-content: space-around;
}

.chipsCardImage {
	width: 65%;
	justify-self: center;
	align-self: center;
}

.chipsCardSection {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100vw;
}

.chipsCardOuterDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.7);

	height: 85%;
	border-radius: 20px;
}

.chipsCardInnerDiv {
	width: 90%;
	height: 80%;
	display: flex;
	flex-direction: column;
	z-index: 2;
}

.contactButton {
	background-color: rgb(26, 170, 88);
	border-radius: 60px;
	border: none;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 4vw;
	padding-right: 4vw;
	font-size: 1.2em;
	color: white;
	font-weight: 400;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	max-width: 40vw;
}

.contactInfoBox {
	border: solid;
	border-color: rgb(222, 222, 222);
	border-width: 0.1px;
	padding-top: 1vh;
	padding-right: 5%;
	padding-left: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 7vh;
	font-size: 1.3em;

	text-align: center;
}

.contactThreeSectionDiv {
	padding-top: 10vh;
	padding-bottom: 10vh;
	padding-right: 2vw;
	padding-left: 2vw;
	display: flex;

	justify-content: space-between;
	justify-items: center;
	align-content: center;
	width: 90%;
	box-shadow: 0px 40px 29px 15px rgba(0, 0, 0, 0.06);
}

.customDropDown:focus {
	border: none;
	box-shadow: none !important;
}

.dropdown-toggle {
	background-color: transparent !important;
	color: black !important;
	border: none !important;
}

.dropdown-toggle::after {
	display: none !important;
}

.faqDiv {
	display: flex;
}

.footerColumns {
	height: 10%;
}

.footerThreeColumns {
	background-color: rgb(243, 147, 34);
	color: white;
	padding-top: 12vh;
	padding-right: 5vw;
	padding-left: 5vw;
	padding-bottom: 2vh;
	margin-top: -2vh;
	z-index: 4;
	position: relative;
	display: flex;
}

.footerLinkText {
	display: flex;
	flex-direction: column;
}

.greenBag {
	color: #1aaa58;
	font-size: 1.5em;
}

.greenCheck {
	color: #1aaa58;
	font-size: 1.25em;
	margin-right: 0.5vw;
}

.healthRegisterSection {
	width: 100vw;
	display: flex;
	justify-content: center;
	padding-bottom: 10vh;
	padding-top: 5vh;
	background-color: white;
}



.iconSpacing {
	margin-right: 0.5vw;
}

.ingredientsCheckList {
	display: flex;
	flex-direction: row !important;
	
	align-items: center;
	margin-top: 1vh;
	margin-bottom: 1vh;
}

.ingredientsCheckList p {
	margin-bottom: 0;
}

.merriweatherFont {
	font-family: 'Merriweather', serif;
	font-weight: 900;
}

.noAddedIngredients {
	display: flex;
	flex-direction: column;
	align-items: flex-start !important
	;
	text-align: left !important;
	margin-left: 2vw;
}

.productBanner {
	position: relative;
	background-position: bottom center;
	font-family: 'Aclonica', sans-serif;
}

.productBanner::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	background-image: url(./Images/DownloadedFromSite/ACHIRAS-DEL-HUILA-ACH4.png);
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	z-index: 0;
}

.productBannerRowDiv {
	display: flex;
}

.productBanner > * {
	z-index: 10;
	position: relative;
}

.productBannerRed::after {
	background-image: url(./Images/DownloadedFromSite/ACHIRAS-DEL-HUILA-CHR1.png);
}

.productBannerBrown::after {
	background-image: url(./Images/DownloadedFromSite/ACHIRAS-DEL-HUILA-ALN4.png);
}

.productBannerYellow::after {
	background-image: url(./Images/DownloadedFromSite/ACHIRAS-DEL-HUILA-ACH4.png);
}

.noContainsList {
	display: flex;
	flex-direction: row !important;
	font-size: 1.3rem;
	align-items: center;
	margin-top: 1.5vh;
	margin-bottom: 1.5vh;
}

.noContainsList i {
	margin-right: 2vw;
	font-size: 2rem;
	border: 5px double #000000;
	border-radius: 40px 40px 40px 40px;
	padding: 0.6vw;
}

.noContainsList h2 {
	margin-bottom: 0;
}

.noListItemDot {
	list-style-type: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1vh;
}

.snackPresentationDiv {
	display: flex;
	flex-direction: column;
	height: 40vh;
	align-items: center;
	justify-content: center;
}

.snackPresentationHeader {
	font-family: 'Aclonica', sans-serif;
	text-align: center;
}

.snackPresentationImg {
	height: 80%;
}

.snackPresentationSection {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	width: 100vw;
	height: auto;
}

.startChipsThreeSectionDiv {
	width: 90%;
	height: 85%;
	min-height: 85%;
	display: flex;

	justify-content: space-between;
}

.startPageMiniBanner {
	font-family: 'Aclonica', sans-serif;
	width: 100vw;
	background-size: cover;
	background-position-y: 100%;
	display: flex;

	align-items: center;
	padding: 3vh 10vw 3vh 10vw;
}

.startPageSlideShowSection {
	width: 100vw;
	background-color: white;
	padding: 2vw;
}

.startPageSlideShowAndText {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.startPageSlideShowAndTextSecondDiv {
	height: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.usFirstSectionDiv {
	background-color: white;
	padding-top: 50px;
	padding-bottom: 50px;
	padding: 50px 7vw 50px 7vw;
	margin-top: -1vh;
	display: flex;
}

.usOrangeCompanySection div {
	background-color: rgb(243, 147, 34);
	color: white;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	padding-top: 1vh;
	padding-bottom: 1vh;
	padding-right: 7vw;
	padding-left: 7vw;
	height: 100%;
}

.usSlideShowSection {
	display: flex;
}

.wavesPeopleAchiraGroup {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-repeat: no-repeat;
	background-position-x: center;
	margin-top: -20vh;
	margin-bottom: -1vh;
}

.WoodenBackground {
	background-image: url('../src/Images/DownloadedFromSite/ADH-FONDO.jpg');
	background-attachment: fixed;
	width: 100vw;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
